import { useEffect, useRef, useState } from "react";
import cnBind from "classnames/bind";
import type { Swiper as SwiperCore } from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import out from "@/shared/assests/Image (6).png";
import { ArrowNextIcon, ArrowPrevIcon } from "@/shared/assests/svg/svg";
import { CustomImage } from "@/shared/ui/CustomImage";

import "swiper/css";
import "swiper/css/navigation";

import styles from "./AboutUs.module.scss";

const cx = cnBind.bind(styles);

export const AboutUsMob: React.FC = () => {
    const swiperRef = useRef<SwiperCore | null>(null);

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on("slideChange", () => {
                setIsBeginning(swiperRef.current?.isBeginning ?? false);
                setIsEnd(swiperRef.current?.isEnd ?? false);
            });
        }
    }, []);

    return (
        <div className={cx("about-us")}>
            <div className={cx("wrapper")}>
                <div className={cx("image-wrapper")}>
                    <CustomImage width={784} height={378} src={out} alt="tezam" className={cx("image")} />
                    <h2 className={cx("title")}>Мы предлагаем</h2>
                </div>
                <div />
                <Swiper
                    modules={[Navigation]}
                    direction="horizontal"
                    slidesPerView={1}
                    spaceBetween={20}
                    breakpoints={{ 1024: { slidesPerView: 1, spaceBetween: 0, enabled: false } }}
                    navigation={{ nextEl: ".controller-next", prevEl: ".controller-prev" }}
                    className={cx("list")}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                >
                    <SwiperSlide className={cx("text")}>
                        <span className={cx("title")}>
                            Tezam — это удобный и современный способ взаимодействия с клиентами в мессенджере
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={cx("text")}>
                        <span className={cx("title")}>
                            Клиенты общаются с менеджером, выбирают товары и услуги, а вы легко управляете процессом
                            через CRM или внутренней панелью управления!
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={cx("text")}>
                        <span className={cx("title")}>
                            Приложение подходит для продажи товаров, записи на услуги, получение заявок на мероприятия и
                            оно работает прямо в чате, без установки.
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={cx("text")}>
                        <span className={cx("title")}>
                            Приложение, которое не нужно размещать в Google Play или App store. Разместите ваш бизнес
                            там, где находится ваша аудитория — Telegram
                        </span>
                    </SwiperSlide>
                </Swiper>
                <div className={cx("controller")}>
                    <div className={cx("controller-prev", { isBeginning })}>
                        <ArrowPrevIcon />
                    </div>
                    <div className={cx("controller-next", { isEnd })}>
                        <ArrowNextIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};
