import { useEffect, useRef, useState } from "react";
import cnBind from "classnames/bind";
import type { Swiper as SwiperCore } from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import card_1 from "@/shared/assests/Card Team (1).png";
import card_2 from "@/shared/assests/Card Team.png";
import { ArrowNextIcon, ArrowPrevIcon } from "@/shared/assests/svg/svg";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./Team.module.scss";

const cx = cnBind.bind(styles);

export const Team = () => {
    const { windowScreen } = useResizeContext();
    const swiperRef = useRef<SwiperCore | null>(null);

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on("slideChange", () => {
                setIsBeginning(swiperRef.current?.isBeginning ?? false);
                setIsEnd(swiperRef.current?.isEnd ?? false);
            });
        }
    }, []);

    return (
        <div className={cx("team")}>
            <div className={cx("wrapper", windowScreen > 1024 && "container")}>
                <h2 className={cx(windowScreen < 1024 && "container")}>
                    <p className={cx("title")}>Мы пришли на смену</p>
                    <p>неповоротливым и дорогим решениям</p>
                </h2>

                {windowScreen > 1024 ? (
                    <div className={cx("list")}>
                        <div className={cx("cards")}>
                            <div className={cx("card")}>
                                <CustomImage className={cx("image")} src={card_1} alt="tezam" />
                            </div>
                            <div className={cx("card")}>
                                <CustomImage className={cx("image")} src={card_2} alt="tezam" />
                            </div>
                        </div>

                        <div className={cx("card-team")}>
                            <h3>Команда проекта</h3>
                            <ul>
                                <li>Проектный менеджер</li>
                                <li>Бизнес-аналитик</li>
                                <li>UX/UI дизайнер</li>
                                <li>Front-End разработчик</li>
                                <li>Back-End разработчик</li>
                                <li>DevOps-инженер</li>
                                <li>Тестировщик (QA-инженер)</li>
                            </ul>
                            <p>Все что нужно без раздутого бюджета</p>
                        </div>
                    </div>
                ) : (
                    <>
                        {windowScreen >= 720 && windowScreen <= 1024 ? (
                            <Swiper
                                modules={[Navigation]}
                                direction="horizontal"
                                slidesPerView={2}
                                slidesPerGroup={2}
                                spaceBetween={10}
                                enabled
                                navigation={{ nextEl: ".controller-next", prevEl: ".controller-prev" }}
                                className={cx("list", "container")}
                                onSwiper={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                            >
                                <SwiperSlide>
                                    <div className={cx("card", "container")}>
                                        <CustomImage className={cx("image")} src={card_1} alt="tezam" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={cx("card", "container")}>
                                        <CustomImage className={cx("image")} src={card_2} alt="tezam" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className={cx("container")}>
                                    <div className={cx("card-team")}>
                                        <h3>Команда проекта</h3>
                                        <ul>
                                            <li>Проектный менеджер</li>
                                            <li>Бизнес-аналитик</li>
                                            <li>UX/UI дизайнер</li>
                                            <li>Front-End разработчик</li>
                                            <li>Back-End разработчик</li>
                                            <li>DevOps-инженер</li>
                                            <li>Тестировщик (QA-инженер)</li>
                                        </ul>
                                        <p>Все что нужно без раздутого бюджета</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        ) : (
                            <Swiper
                                modules={[Navigation]}
                                direction="horizontal"
                                slidesPerView={1}
                                slidesPerGroup={1}
                                spaceBetween={10}
                                enabled
                                navigation={{ nextEl: ".controller-next", prevEl: ".controller-prev" }}
                                className={cx("list", "container")}
                                onSwiper={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                            >
                                <SwiperSlide>
                                    <div className={cx("card", "container")}>
                                        <CustomImage className={cx("image")} src={card_1} alt="tezam" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={cx("card", "container")}>
                                        <CustomImage className={cx("image")} src={card_2} alt="tezam" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className={cx("container")}>
                                    <div className={cx("card-team")}>
                                        <h3>Команда проекта</h3>
                                        <ul>
                                            <li>Проектный менеджер</li>
                                            <li>Бизнес-аналитик</li>
                                            <li>UX/UI дизайнер</li>
                                            <li>Front-End разработчик</li>
                                            <li>Back-End разработчик</li>
                                            <li>DevOps-инженер</li>
                                            <li>Тестировщик (QA-инженер)</li>
                                        </ul>
                                        <p>Все что нужно без раздутого бюджета</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        )}
                        <div className={cx("controller")}>
                            <div className={cx("controller-prev", { isBeginning })}>
                                <ArrowPrevIcon />
                            </div>
                            <div className={cx("controller-next", { isEnd })}>
                                <ArrowNextIcon />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
