import iphone16_1 from "@/shared/assests/iPhone 16 (1).png";
import iphone16_2 from "@/shared/assests/iPhone 16 (2).png";
import iphone16_3 from "@/shared/assests/iPhone 16 (3).png";
import iphone16_4 from "@/shared/assests/iPhone 16 (4).png";
import iphone16_5 from "@/shared/assests/iPhone 16 (5).png";
import iphone16_6 from "@/shared/assests/iPhone 16 (6).png";
import iphone16_7 from "@/shared/assests/iPhone 16 (7).png";
import iphone16_8 from "@/shared/assests/iPhone 16 (8).png";
import iphone16 from "@/shared/assests/iPhone 16.png";
import iphone16_0 from "@/shared/assests/iPhone 16(0).png";

export const listCase = [
    {
        caption: "Салоны красоты",
        list: [
            {
                title: "Запись к мастерам:",
                text: "Простая система записи с выбором мастера и времени, отправка уведомлений о записи и напоминаний",
            },
            {
                title: "Продажа товаров:",
                text: "Онлайн-каталог косметики и средств ухода, возможность заказа прямо в мессенджере",
            },
            {
                title: "Отзывы и рейтинги:",
                text: "Клиенты могут оставлять отзывы о мастерах и услугах, что повышает доверие",
            },
        ],
        img: iphone16,
    },
    {
        caption: "Интернет-магазины",
        list: [
            {
                title: "Каталог товаров и корзина:",
                text: "Прямой доступ к товарам, добавление в корзину, оформление заказа, уведомления о статусе",
            },
            {
                title: "Персонализированные рекомендации:",
                text: "На основе истории покупок или интересов клиента",
            },
            {
                title: "Поддержка клиентов:",
                text: "Автоматизация часто задаваемых вопросов и возможность чата с менеджером",
            },
        ],
        img: iphone16_8,
    },
    {
        caption: "Сеть фитнес-клубов и тренеры",
        list: [
            {
                title: "Запись на занятия:",
                text: "Удобное бронирование классов или персональных тренировок с выбором тренера и расписанием",
            },
            {
                title: "Напоминания и уведомления:",
                text: "Уведомления о занятиях, напоминания о тренировках, уведомления о доступных слотах",
            },
            {
                title: "Отслеживание прогресса:",
                text: "Подсчёт посещений, статистика, а также возможность получать персонализированные планы тренировок",
            },
        ],
        img: iphone16_7,
    },
    {
        caption: "Стоматологии и клиники",
        list: [
            {
                title: "Запись на приём:",
                text: "Возможность выбрать врача, дату и время визита с получением подтверждения",
            },
            {
                title: "Предоставление информации о процедуре:",
                text: "Описание услуг, прайс-лист, пошаговое объяснение, доступное перед приёмом",
            },
            {
                title: "Напоминания и предзапись:",
                text: "Напоминания за день до записи, уведомления о предстоящем визите, что снижает пропуски",
            },
        ],
        img: iphone16_6,
    },
    {
        caption: "Рестораны и кафе",
        list: [
            {
                title: "Бронирование столиков:",
                text: "Выбор времени и количества гостей, получение уведомления о подтверждении брони",
            },
            {
                title: "Предзаказ блюд:",
                text: "Возможность заказать блюда заранее для более быстрого обслуживания на месте",
            },
            {
                title: "Система лояльности:",
                text: "Предложение специальных акций, бонусов и накопления баллов для постоянных клиентов",
            },
        ],
        img: iphone16_5,
    },
    {
        caption: "Агентства недвижимости",
        list: [
            {
                title: "Консультации и подбор объектов",
                text: "Автоматизация первичного подбора жилья на основе предпочтений клиента, чат с менеджером",
            },
            {
                title: "Планировщик показов:",
                text: "Запись на просмотры объектов, уведомления о запланированных показах",
            },
            {
                title: "Онлайн-тур по объектам:",
                text: "Виртуальный показ объектов через медиаматериалы или видеозвонок, возможность задать вопросы онлайн",
            },
        ],
        img: iphone16_4,
    },
    {
        caption: "Автосервисы и шиномонтажи",
        list: [
            {
                title: "Запись на обслуживание",
                text: "Выбор услуги, запись по свободным слотам с подтверждением",
            },
            {
                title: "Отчёты о работе и расчёты",
                text: "Отправка отчётов о проделанной работе и предложений о дополнительных услугах",
            },
            {
                title: "Уведомления о готовности:",
                text: "Уведомления о завершении работы, согласование деталей через чат",
            },
        ],
        img: iphone16_3,
    },
    {
        caption: "Логистические компании",
        list: [
            {
                title: "Отслеживание заказов:",
                text: "Автоматическое уведомление о статусе доставки и отслеживание на всех этапах",
            },
            {
                title: "Поддержка клиентов:",
                text: "Мгновенные ответы на частые вопросы и возможность уточнить детали",
            },
            {
                title: "Уведомления о доставке:",
                text: "Уведомления о приближении курьера и подтверждение доставки",
            },
        ],
        img: iphone16_2,
    },
    {
        caption: "Учебные центры и курсы",
        list: [
            {
                title: "Запись на занятия:",
                text: "Бронирование мест на занятия, курсы и вебинары с удобным выбором расписания",
            },
            {
                title: "Персонализированные уведомления:",
                text: "Уведомления о начале и окончании курса, о предстоящих занятиях и домашних заданиях",
            },
            {
                title: "Оценка и обратная связь:",
                text: "Возможность оставлять отзывы, оценки, а также отслеживать прогресс",
            },
        ],
        img: iphone16_1,
    },
    {
        caption: "Студии йоги и медитации",
        list: [
            {
                title: "Запись на сеансы и классы:",
                text: "Выбор времени, типа занятий и инструктора, получение подтверждения",
            },
            {
                title: "Информация о занятиях:",
                text: "Описание типов занятий, их уровня, продолжительности",
            },
            {
                title: "Личная статистика:",
                text: "История посещений и рекомендации на основе уровня клиента и его предпочтений",
            },
        ],
        img: iphone16_0,
    },
];
