import { useEffect, useRef } from "react";
import cnBind from "classnames/bind";

import { useResizeContext } from "@/shared/context/WindowResizeProvider";

import styles from "./Video.module.scss";

const cx = cnBind.bind(styles);

export const Video = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const { windowScreen } = useResizeContext();
    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            video.currentTime = 2;
            void video.play();
        }
    }, []);

    return (
        <div className={cx("video")}>
            <video
                ref={videoRef}
                width="100%"
                height="100%"
                src={windowScreen < 1024 ? "/MAIN_COMP_8.webm" : "/MAIN_COMP_2.webm"}
                playsInline
                loop
                muted
                autoPlay
                preload="auto"
            >
                <source src={windowScreen < 1024 ? "/MAIN_COMP_8.webm" : "/MAIN_COMP_2.webm"} type="video/webm" />
            </video>
        </div>
    );
};
