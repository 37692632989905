import React, { useState } from "react";
import cnBind from "classnames/bind";

import { ModalCallBack } from "@/components/_Modal/ModalCallBack";
import image_2 from "@/shared/assests/IconContainer_3 (1).png";
import image_3 from "@/shared/assests/IconContainer_3 (2).png";
import image_4 from "@/shared/assests/IconContainer_3 (3).png";
import image_1 from "@/shared/assests/IconContainer_3.png";
import { TezamIcon } from "@/shared/assests/svg/svg";
import { useBooleanState } from "@/shared/hooks";
import { Item } from "@/view/Main/App/Item";

import styles from "./App.module.scss";

const cx = cnBind.bind(styles);

export const App: React.FC = () => {
    const [transform, setTransform] = useState<string>("translate(0, 0)");
    const [isOpenModal, openModal, closeModal] = useBooleanState(false);
    const list = [
        {
            title: "Полноценное приложение",
            description: "Так же если бы вы скачали его из Google Play & App Store",
            image: image_1.src,
        },
        {
            title: "Быстрая загрузка",
            description: "Web-приложение не занимает место на смартфоне пользователя",
            image: image_2.src,
        },
        {
            title: "Низкая стоимость разработки",
            description: "Модульная разработка удешевляет стоимость уникальных функций",
            image: image_3.src,
        },
        {
            title: "Работает на всех устройствах",
            description: "Его не нужно скачивать, достаточно просто открыть мессенджер",
            image: image_4.src,
        },
    ];

    const handleMouseEnter = (index: number) => {
        const offset = 100;
        const translateValue = `translate(${index % 2 === 0 ? -offset : offset}px, ${Math.floor(index / 2) === 0 ? -offset : offset}px)`;
        setTransform(translateValue);
    };

    const handleMouseLeave = () => {
        setTransform("translate(0, 0)");
    };

    return (
        <div className={cx("app")}>
            <div className={cx("wrapper", "container")}>
                <h2>Приложение внутри Telegram автоматизирующее взаимодействие бизнеса с клиентом</h2>

                <div className={cx("list")}>
                    <div className={cx("items")}>
                        <Item
                            image={list[0].image}
                            title={list[0].title}
                            description={list[0].description}
                            onMouseEnter={() => handleMouseEnter(0)}
                            onMouseLeave={handleMouseLeave}
                        />
                        <Item
                            image={list[1].image}
                            title={list[1].title}
                            description={list[1].description}
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}
                        />
                    </div>
                    <div
                        className={cx("parent")}
                        onClick={openModal}
                        style={{ transform, transition: "transform 0.6s ease-out" }}
                    >
                        <div className={cx("item-main")}>
                            <TezamIcon className={cx("icon")} />
                        </div>
                        <div className={cx("item-main", "blur-container")}>
                            <TezamIcon className={cx("icon")} />
                        </div>
                    </div>
                    <div className={cx("items")}>
                        <Item
                            image={list[2].image}
                            title={list[2].title}
                            description={list[2].description}
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={handleMouseLeave}
                        />
                        <Item
                            image={list[3].image}
                            title={list[3].title}
                            description={list[3].description}
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={handleMouseLeave}
                        />
                    </div>
                </div>
            </div>
            <ModalCallBack isOpen={isOpenModal} onClose={closeModal} />
        </div>
    );
};
