import cnBind from "classnames/bind";

import out from "@/shared/assests/Image (6).png";
import { CustomImage } from "@/shared/ui/CustomImage";

import "swiper/css";

import styles from "./AboutUs.module.scss";

const cx = cnBind.bind(styles);

export const AboutUs = () => {
    return (
        <div className={cx("about-us")}>
            <div className={cx("wrapper", "container")}>
                <div className={cx("image-wrapper")}>
                    <CustomImage width={784} height={378} src={out} alt="tezam" className={cx("image")} />
                    <h2 className={cx("title")}>Мы предлагаем</h2>
                </div>
                <div className={cx("list")}>
                    <div className={cx("text")}>
                        <span className={cx("title")}>
                            Tezam — это удобный и современный способ взаимодействия с клиентами в мессенджере
                        </span>
                    </div>
                    <div className={cx("text")}>
                        <span className={cx("title")}>
                            Клиенты общаются с менеджером, выбирают товары и услуги, а вы легко управляете процессом
                            через CRM или внутренней панелью управления!
                        </span>
                        <span className={cx("title")}>
                            Приложения подходит для продажи товаров, записи на услуги, получение заявок на мероприятия и
                            оно работает прямо в чате, без установки.
                        </span>
                    </div>
                    <div className={cx("text")}>
                        <span className={cx("title")}>
                            Приложение, которое не нужно размещать в Google Play или App store. Разместить ваш бизнес
                            там, где находится ваша аудитория — Telegram
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
