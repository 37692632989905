import cnBind from "classnames/bind";
import { motion } from "motion/react";
import type { StaticImageData } from "next/image";

import { ModalCallBack } from "@/components/_Modal/ModalCallBack";
import { ModalViewImage } from "@/components/_Modal/ModalViewImage";
import { TelegramIcon } from "@/shared/assests/svg/svg";
import { useBooleanState } from "@/shared/hooks";
import { Button } from "@/shared/ui/_Button";
import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./CaseBlock.module.scss";

const cx = cnBind.bind(styles);
type CaseBlockProps = {
    caption: string;
    list: { title: string; text: string }[];
    img: StaticImageData;
};
const caseBlockVariants = {
    enter: { opacity: 0, y: 20 },
    center: { opacity: 1, y: 0, transition: { duration: 0.7, ease: "easeInOut" } },
    exit: { opacity: 0, y: 20, transition: { duration: 0.7, ease: "easeInOut" } },
};
const captionVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0, transition: { duration: 0.7, ease: "easeInOut" } },
};

export const CaseBlock = ({ caption, img, list }: CaseBlockProps) => {
    const [isOpenModal, openModal, closeModal] = useBooleanState(false);
    const [isOpenModalImg, openModalImg, closeModalImg] = useBooleanState(false);

    return (
        <motion.div
            className={cx("case-block")}
            initial="enter"
            animate="center"
            exit="exit"
            variants={caseBlockVariants}
        >
            <div className={cx("content")}>
                <motion.h3 className={cx("title")}>Готовое решения для бизнеса</motion.h3>
                <motion.h2 className={cx("caption")} initial="initial" animate="animate" variants={captionVariants}>
                    {caption}
                </motion.h2>
                <motion.ul
                    className={cx("list")}
                    initial="initial"
                    animate="animate"
                    variants={{
                        initial: { opacity: 0 },
                        animate: { opacity: 1, transition: { staggerChildren: 0.1 } },
                    }}
                >
                    {list.map((item) => (
                        <motion.li
                            key={item.title}
                            className={cx("list-item")}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, ease: "easeInOut", delay: 0.1 * list.indexOf(item) }}
                        >
                            <span className={cx("list-item-title")}>{item.title}</span>
                            <span className={cx("list-item-text")}>{item.text}</span>
                        </motion.li>
                    ))}
                </motion.ul>
                <div className={cx("button-wrapper")}>
                    <Button
                        onClick={openModal}
                        className={cx("button")}
                        label="Telegram"
                        icon={<TelegramIcon className={cx("icon")} />}
                    />
                    <div className={cx("image-mobile")}>
                        <CustomImage
                            onClick={openModalImg}
                            width={60}
                            height={60}
                            src={img}
                            className={cx("img")}
                            alt={caption}
                        />
                    </div>
                </div>
            </div>
            <div className={cx("img-wrapper")}>
                <CustomImage width={708} height={600} src={img} className={cx("img")} alt={caption} />
            </div>
            <ModalCallBack isOpen={isOpenModal} onClose={closeModal} />
            <ModalViewImage isOpen={isOpenModalImg} closeModal={closeModalImg} src={img.src} />
        </motion.div>
    );
};
