import { useEffect, useState } from "react";
import cnBind from "classnames/bind";
import { useFormik } from "formik";
import * as Yup from "yup";

import { TelegramIcon } from "@/shared/assests/svg/svg";
import { Button } from "@/shared/ui/_Button";
import { InputText } from "@/shared/ui/_InputText";

import styles from "./FormCallBack.module.scss";

const cx = cnBind.bind(styles);

interface FormValues {
    telegram: string;
    firstName: string;
    email: string;
    business: string;
}

export const validationSchema = Yup.object({
    telegram: Yup.string().required("Обязательное поле"),
    firstName: Yup.string().required("Обязательное поле"),
    email: Yup.string().email("Неправильный формат email").required("Обязательное поле"),
    business: Yup.string().required("Обязательное поле"),
});

export const FormCallBack = () => {
    const formik = useFormik<FormValues>({
        initialValues: {
            telegram: "",
            firstName: "",
            email: "",
            business: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await fetch("https://tezam.ru/api/send-email", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                });

                if (response.ok) {
                    alert("Ваше сообщение отправлено!");
                    resetForm();
                } else {
                    alert("Ошибка при отправке сообщения.");
                }
            } catch (error) {
                alert("Ошибка при отправке сообщения.");
            }
        },
    });

    const [currentTitle, setCurrentTitle] = useState(0);
    const [fadeClass, setFadeClass] = useState("");
    const titles = ["Готовы попробовать?", "Свяжитесь с нами!"];

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeClass("fade-out"); // Запускаем эффект ухода
            setTimeout(() => {
                setCurrentTitle((prev) => (prev + 1) % titles.length);
                setFadeClass("fade-in"); // Запускаем эффект прихода
            }, 1000); // Время для завершения ухода
        }, 4000); // Полный цикл анимации 4 секунды: 3 секунды показ + 1 секунда анимация

        return () => clearInterval(interval);
    }, [titles.length]);

    return (
        <div className={cx("form-callback")}>
            <div className={cx("title-container")}>
                <h3 className={cx(fadeClass)}>{titles[currentTitle]}</h3>
            </div>
            <form onSubmit={formik.handleSubmit} className={cx("form")}>
                <div className={cx("inputs")}>
                    <div className={cx("input")}>
                        <InputText
                            placeholder="Telegram*"
                            name="telegram"
                            value={formik.values.telegram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.telegram && formik.errors.telegram ? formik.errors.telegram : ""}
                            isFullWidth
                            className={cx("input-text")}
                        />
                        <InputText
                            placeholder="Имя"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ""}
                            isFullWidth
                            className={cx("input-text")}
                        />
                    </div>
                    <div className={cx("input")}>
                        <InputText
                            placeholder="Email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && formik.errors.email ? formik.errors.email : ""}
                            isFullWidth
                            className={cx("input-text")}
                        />
                        <InputText
                            placeholder="Бизнес"
                            name="business"
                            value={formik.values.business}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.business && formik.errors.business ? formik.errors.business : ""}
                            isFullWidth
                            className={cx("input-text")}
                        />
                    </div>
                </div>

                <Button
                    type="submit"
                    className={cx("button")}
                    label="Telegram"
                    icon={<TelegramIcon className={cx("icon")} />}
                />
            </form>
        </div>
    );
};
