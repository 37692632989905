// @flow
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import cnBind from "classnames/bind";
import type { Swiper as SwiperCore } from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Modal } from "@/components/_Modal/Modal";
import { ArrowNextIcon, ArrowPrevIcon, CloseSlideIcon } from "@/shared/assests/svg/svg";
import { CustomImage } from "@/shared/ui/CustomImage";
import { listSlide } from "@/view/Main/WhyAreWe/const";

import "swiper/css";
import "swiper/css/navigation";

import styles from "./ModalViewSlide.module.scss";

const cx = cnBind.bind(styles);
type Props = {
    isOpen: boolean;
    closeModal: (index: number) => void;
};
export const ModalViewSlide = ({ closeModal, isOpen }: Props) => {
    const swiperRef = useRef<SwiperCore | null>(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on("slideChange", () => {
                setIsBeginning(swiperRef.current?.isBeginning ?? false);
                setIsEnd(swiperRef.current?.isEnd ?? false);
            });
        }
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={() => closeModal} className={cx("modal-view")} maxWidth="100%" height="100dvh">
            <Swiper
                direction="horizontal"
                slidesPerView={1}
                slidesPerGroup={1}
                spaceBetween={10}
                navigation={{ nextEl: ".controller-next", prevEl: ".controller-prev" }}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                modules={[Navigation]}
            >
                {listSlide.map(({ title, description, img }, index) => (
                    <SwiperSlide className={cx("slide")} key={title}>
                        <div className={cx("image-wrapper")}>
                            <CustomImage width={1000} height={1000} src={img} alt="tezam" className={cx("image")} />
                            <CloseSlideIcon className={cx("close-icon")} onClick={() => closeModal(index)} />
                        </div>
                        <div className={cx("text-wrapper", "container")}>
                            <h3>{title}</h3>
                            <p>{description}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={cx("controller")}>
                <div className={cx("controller-prev", { isBeginning })}>
                    <ArrowPrevIcon />
                </div>
                <div className={cx("controller-next", { isEnd })}>
                    <ArrowNextIcon />
                </div>
            </div>
        </Modal>
    );
};
