import type { ReactNode } from "react";
import React from "react";
import cnBind from "classnames/bind";
import { Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Header } from "@/components/Header";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import useIntersectionObserver from "@/shared/hooks/useIntersectionObserver";
import { AboutUs } from "@/view/Main/AboutUs";
import { AboutUsMob } from "@/view/Main/AboutUs/AboutUsMob";
import { App } from "@/view/Main/App";
import { Case } from "@/view/Main/Case";
import { Decision } from "@/view/Main/Decision/Decision";
import { Faq } from "@/view/Main/Faq";
import { Form } from "@/view/Main/Form";
import { Hero } from "@/view/Main/Hero";
import { Services } from "@/view/Main/Services";
import { Team } from "@/view/Main/Team";
import { Video } from "@/view/Main/Video";
import { WhyAreWe } from "@/view/Main/WhyAreWe";

import styles from "./Main.module.scss";

const cx = cnBind.bind(styles);

interface MainSlideProps {
    children: ReactNode;
}

const MainSlide: React.FC<MainSlideProps> = ({ children }) => {
    const [ref, entry] = useIntersectionObserver({ threshold: 0.5 });
    const isVisible = entry?.isIntersecting;

    return (
        <div ref={ref} className={cx("swiper-slide")} style={{ width: "100vw", height: "100vh" }}>
            {isVisible ? children : null}
        </div>
    );
};

export const Main = () => {
    const { windowScreen } = useResizeContext();

    return (
        <div className={cx("main-page")}>
            {windowScreen > 1024 ? (
                <Swiper
                    modules={[Mousewheel]}
                    direction="vertical"
                    mousewheel={{ releaseOnEdges: true }}
                    speed={1000}
                    style={{ width: "100vw", height: "100vh" }}
                    initialSlide={0}
                >
                    <SwiperSlide>
                        <MainSlide>
                            <Header />
                            <Hero />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <AboutUs />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Video />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Case />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <WhyAreWe />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Services />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Decision />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Faq />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <App />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Team />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Form />
                        </MainSlide>
                    </SwiperSlide>
                </Swiper>
            ) : (
                <Swiper
                    modules={[Mousewheel]}
                    direction="vertical"
                    mousewheel={{ releaseOnEdges: true }}
                    speed={1000}
                    style={{ width: "100vw", height: "100vh" }}
                    initialSlide={0}
                >
                    <SwiperSlide>
                        <MainSlide>
                            <Header />
                            <Hero />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <AboutUsMob />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Video />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Case />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <WhyAreWe />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Services />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Decision />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Faq />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <App />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Team />
                        </MainSlide>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MainSlide>
                            <Form />
                        </MainSlide>
                    </SwiperSlide>
                </Swiper>
            )}
        </div>
    );
};
