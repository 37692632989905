import cnBind from "classnames/bind";
import { useRouter } from "next/router";

import LOGO from "@/shared/assests/svg/Logo_TRUE.svg";
import { Routes } from "@/shared/constants/Routing";
import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./Logo.module.scss";

const cx = cnBind.bind(styles);

export const Logo = () => {
    const router = useRouter();

    return (
        <div className={cx("logo")} onClick={() => router.push(Routes.HOME)}>
            <CustomImage width={138} height={38} src={LOGO as string} alt="tezam" className={cx("image")} />
        </div>
    );
};
