import { useEffect, useRef, useState } from "react";
import cnBind from "classnames/bind";
import type { Swiper as SwiperCore } from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ArrowNextIcon, ArrowPrevIcon } from "@/shared/assests/svg/svg";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import { Card } from "@/view/Main/Decision/Card";

import "swiper/css";
import "swiper/css/navigation";

import styles from "./Decision.module.scss";

const cx = cnBind.bind(styles);

export const Decision = () => {
    const swiperRef = useRef<SwiperCore | null>(null);
    const { windowScreen } = useResizeContext();
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on("slideChange", () => {
                setIsBeginning(swiperRef.current?.isBeginning ?? false);
                setIsEnd(swiperRef.current?.isEnd ?? false);
            });
        }
    }, []);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.navigation) {
            swiperRef.current.navigation.update();
        }
    }, [isBeginning, isEnd]);

    return (
        <div className={cx("decision")}>
            <div className={cx("wrapper", windowScreen > 1024 && "container")}>
                <h2 className={cx(windowScreen < 1024 && "container")}>Быстрое решение сегодня</h2>
                {windowScreen > 1024 ? (
                    <div className={cx("list")}>
                        <Card />
                        <Card isActive />
                    </div>
                ) : (
                    <>
                        <Swiper
                            modules={[Navigation]}
                            direction="horizontal"
                            slidesPerView={1}
                            spaceBetween={0}
                            breakpoints={{ 1024: { slidesPerView: 1, spaceBetween: 0, enabled: false } }}
                            navigation={{ nextEl: ".controller-next", prevEl: ".controller-prev" }}
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper;
                                swiper.on("slideChange", () => {
                                    setIsBeginning(swiper.isBeginning);
                                    setIsEnd(swiper.isEnd);
                                });
                            }}
                            className={cx("list", "container")}
                        >
                            <SwiperSlide className={cx("container")}>
                                <Card />
                            </SwiperSlide>
                            <SwiperSlide className={cx("container")}>
                                <Card isActive />
                            </SwiperSlide>
                        </Swiper>

                        <div className={cx("controller-prev", "arrow", "arrow-prev", { isBeginning })}>
                            <ArrowPrevIcon />
                        </div>
                        <div className={cx("controller-next", "arrow", "arrow-next", { isEnd })}>
                            <ArrowNextIcon />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
