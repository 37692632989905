import cnBind from "classnames/bind";

import { ModalCallBack } from "@/components/_Modal/ModalCallBack";
import { TelegramIcon } from "@/shared/assests/svg/svg";
import { useBooleanState } from "@/shared/hooks";
import { Button } from "@/shared/ui/_Button";

import styles from "./Hero.module.scss";

const cx = cnBind.bind(styles);

export const Hero = () => {
    const [isOpenModal, openModal, closeModal] = useBooleanState(false);

    return (
        <div className={cx("hero")}>
            <div className={cx("background")}>
                <div className={cx("marquee")}>
                    <span>
                        #онлайн-каталог товаров #интернет-магазины #флористические студии и магазины подарков #программа
                        лояльности
                    </span>
                    <span>
                        #поддержка и чат с клиентами #автосервисы и автомойки #консалтинговые агентства #интеграция с
                        CRM #бронирование
                    </span>
                    <span>
                        и запись онлайн #управление расписанием #платежи и подписки #салоны красоты и барбершопы #йога и
                        пилатес
                    </span>
                    <span>
                        центры #поддержка клиентов #уведомления и напоминания #массажные салоны #витрина для клиентов
                        #продажа
                    </span>
                    <span>
                        услуг через чат #туристические агентства #прямые продажи в мессенджере #студии маникюра и
                        педикюра #фитнес-
                    </span>
                    <span>
                        клубы и тренеры #кинотеатры и развлекательные центры #заказ и доставка #интеграция с календарём
                        #онлайн-оплата
                    </span>
                    <span>
                        и квитанции #администрирование товаров #коворкинг и арендные пространства #фидбек и отзывы
                        #рестораны и кафе
                    </span>
                    <span>
                        #калькуляторы стоимости #клининг и бытовые услуги #агентства недвижимости #курсы и
                        образовательные центры
                    </span>
                    <span>#персонализированные уведомления #тренинговые центры и коучи #музеи и выставки</span>
                    {/* Дублирование текста для создания непрерывного эффекта */}
                    <span>
                        #онлайн-каталог товаров #интернет-магазины #флористические студии и магазины подарков #программа
                        лояльности
                    </span>
                    <span>
                        #поддержка и чат с клиентами #автосервисы и автомойки #консалтинговые агентства #интеграция с
                        CRM #бронирование
                    </span>
                    <span>
                        и запись онлайн #управление расписанием #платежи и подписки #салоны красоты и барбершопы #йога и
                        пилатес
                    </span>
                    <span>
                        центры #поддержка клиентов #уведомления и напоминания #массажные салоны #витрина для клиентов
                        #продажа
                    </span>
                    <span>
                        услуг через чат #туристические агентства #прямые продажи в мессенджере #студии маникюра и
                        педикюра #фитнес-
                    </span>
                    <span>
                        клубы и тренеры #кинотеатры и развлекательные центры #заказ и доставка #интеграция с календарём
                        #онлайн-оплата
                    </span>
                    <span>
                        и квитанции #администрирование товаров #коворкинг и арендные пространства #фидбек и отзывы
                        #рестораны и кафе
                    </span>
                    <span>
                        #калькуляторы стоимости #клининг и бытовые услуги #агентства недвижимости #курсы и
                        образовательные центры
                    </span>
                    <span>#персонализированные уведомления #тренинговые центры и коучи #музеи и выставки</span>
                </div>
                <div className={cx("wrapper")}>
                    <h1 className={cx("title", "caption")}>
                        Tezam.<span className={cx("caption-span")}>Разработка</span>
                    </h1>
                    <h2 className={cx("title", "description")}>Telegram mini Apps для вашего бизнеса</h2>
                    <Button
                        onClick={openModal}
                        className={cx("button")}
                        label="Telegram"
                        icon={<TelegramIcon className={cx("icon")} />}
                    />
                </div>
            </div>
            <ModalCallBack isOpen={isOpenModal} onClose={closeModal} />
        </div>
    );
};
