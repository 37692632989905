import cnBind from "classnames/bind";

import { Modal } from "@/components/_Modal/Modal";
import { CloseIcon } from "@/shared/assests/svg/svg";
import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./ModalViewImage.module.scss";

const cx = cnBind.bind(styles);
type Props = {
    isOpen: boolean;
    closeModal: () => void;
    src: string;
};
export const ModalViewImage = ({ closeModal, isOpen, src }: Props) => {
    return (
        <Modal isOpen={isOpen} onClose={closeModal} className={cx("modal-view")} maxWidth="100%" height="100%">
            <CloseIcon className={cx("close-icon")} onClick={closeModal} />
            <div className={cx("image-wrapper")}>
                <CustomImage width={1000} height={1000} src={src} alt="tezam" className={cx("image")} />
            </div>
        </Modal>
    );
};
