import cnBind from "classnames/bind";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import iphone_17 from "@/shared/assests/disSlide/Slide 14.png";
import iphone_18 from "@/shared/assests/disSlide/Slide 15.png";
import iphone_20 from "@/shared/assests/disSlide/Slide 17.png";
import iphone_19 from "@/shared/assests/disSlide/Slide 18.png";
import iphone_16 from "@/shared/assests/iPhone 16.png";
import { CustomImage } from "@/shared/ui/CustomImage";

import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css";

import styles from "./Card.module.scss";

const cx = cnBind.bind(styles);
type CardProps = {
    isActive?: boolean;
};
export const Card = ({ isActive }: CardProps) => {
    const list = [iphone_17, iphone_18, iphone_19, iphone_20];

    return (
        <div className={cx("card")}>
            {!isActive ? (
                <div className={cx("image-container")}>
                    <Swiper
                        modules={[Autoplay, EffectFade]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        loop
                        className={cx("swiper")}
                    >
                        {list.map((el, inx) => (
                            <SwiperSlide className={cx("image-wrapper")} key={inx}>
                                <CustomImage src={el} alt="Tezam" className={cx("image")} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <div className={cx("image-container")}>
                    <CustomImage src={iphone_16} alt="Tezam" className={cx("image", "image-scale")} />
                </div>
            )}
            {isActive ? (
                <div className={cx("content")}>
                    <h3>Легко Управлять, настраи&shy;вать и стилизовать</h3>
                    <p>Мы предлагаем кастомизацию приложения под потребности бизнеса:</p>
                    <ul>
                        <li>Интеграция с текущими системами учёта заявок (CRM, 1С и другие).</li>
                        <li>Собственная система управления:</li>
                    </ul>
                    <p>Доступ через удобную веб-панель (десктопное приложение).</p>
                    <p>Управление прямо в Telegram через внутреннюю панель.</p>
                </div>
            ) : (
                <div className={cx("content")}>
                    <h3>Tezam — приложение по предоставлению услуг</h3>
                    <p>
                        Текущая версия приложения специально разработана для бизнеса, ориентированного на продажу услуг.
                        Это идеальное решение для:
                    </p>
                    <ul>
                        <li>
                            <span>Салонов красоты:</span> запись на процедуры, управление мастерами и услугами.
                        </li>
                        <li>
                            <span>Барбершопов:</span> удобное бронирование и продвижение услуг.
                        </li>
                        <li>
                            <span>Массажных салонов:</span> персонализированные предложения для клиентов, возможность
                            бронирования времени.
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
