export { ReactComponent as ArrowGradIcon, default as ArrowGradIconPath } from "./arrowGrad.svg";
export { ReactComponent as ArrowNextIcon, default as ArrowNextIconPath } from "./arrowNext.svg";
export { ReactComponent as ArrowPrevIcon, default as ArrowPrevIconPath } from "./arrowPrev.svg";
export { ReactComponent as CloseIcon, default as CloseIconPath } from "./close.svg";
export { ReactComponent as CloseMenuIcon, default as CloseMenuIconPath } from "./close-menu.svg";
export { ReactComponent as CloseSlideIcon, default as CloseSlideIconPath } from "./close-slide.svg";
export { ReactComponent as InIcon, default as InIconPath } from "./in.svg";
export { ReactComponent as MailIcon, default as MailIconPath } from "./tabler-icon-mail-forward.svg";
export { ReactComponent as TelegramIcon, default as TelegramIconPath } from "./telegram.svg";
export { ReactComponent as TezamIcon, default as TezamIconPath } from "./TEZAM.svg";
export { ReactComponent as YoutubeIcon, default as YoutubeIconPath } from "./youtube.svg";
