import serv_1 from "@/shared/assests/services/Image (6).png";
import serv_2 from "@/shared/assests/services/Image (7).png";
import serv_3 from "@/shared/assests/services/Image (8).png";
import serv_4 from "@/shared/assests/services/Image (9).png";
import serv_5 from "@/shared/assests/services/Image (10).png";
import serv_6 from "@/shared/assests/services/Image (11).png";

export const services = [
    {
        title: "Разработка приложения по запросу",
        desc: "Tezam это версия приложения, в которую включены модули записи на услуги, продажи товаров и интеграцию с CRM-системой и календарём",
        image: serv_1.src,
    },
    {
        title: "Быстрая настройка под ваш бизнес",
        desc: "Мы создадим уникальное приложения под ваши нужды, мы можем как расширить свой текущий функционал так и сделать для вас нечто уникальное",
        image: serv_2.src,
    },
    {
        title: "UX UI дизайн",
        desc: "UX UI дизайнеры создадут лучшее решения для вас. В их руках библиотека компонентов от Telegram и обширный опыт создания мобильных приложений для Android & IOS",
        image: serv_3.src,
    },
    {
        title: "Внедрение",
        desc: "Система включает в себя полноценное управление приложением по системе ролей. Мы обучим ваш персонал пользоваться всеми преимуществами сервиса",
        image: serv_4.src,
    },
    {
        title: "Сопровождение",
        desc: "Мы предлагаем месяц бесплатной тех. поддержки чтобы все возникающие у вас вопросы были мгновенно закрыты, а технические сложности решались не успев возникнуть",
        image: serv_5.src,
    },
    {
        title: "Размещение в агрегаторе",
        desc: "Наша команда разрабатывает агрегатор товаров и услуг на подобие больших маркетплейсов. Приложения из разных сфер бизнеса мы подключило в локальный агрегатор погородам",
        image: serv_6.src,
    },
];
