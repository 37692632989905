import { useState } from "react";
import cnBind from "classnames/bind";
import { useRouter } from "next/router";
import { Button } from "primereact/button";

import { ModalCallBack } from "@/components/_Modal/ModalCallBack";
import { ModalMenu } from "@/components/_Modal/ModalMenu";
import { Logo } from "@/components/Logo";
import { InIcon, MailIcon, TelegramIcon, YoutubeIcon } from "@/shared/assests/svg/svg";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import { useBooleanState } from "@/shared/hooks";

import styles from "./Header.module.scss";

const cx = cnBind.bind(styles);

export const Header = () => {
    const { windowScreen } = useResizeContext();
    const href = useRouter();
    const [activeLocale, setActiveLocale] = useState<boolean>(false);
    const handleLocale = () => setActiveLocale((prev) => !prev);
    const [isOpen, open, close] = useBooleanState(false);
    const [isOpenModal, openModal, closeModal] = useBooleanState(false);

    return (
        <header className={cx("header")}>
            <div className={cx("wrapper", "container")}>
                <Logo />
                {/* <div className={cx("menu")}>*/}
                {/*    {windowScreen >= 1100 && (*/}
                {/*        <div className={cx("nav")}>*/}
                {/*            <Navbar className={cx("navbar-header")} />*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/* </div>*/}
                <div className={cx("call")}>
                    <div className={cx("locale")}>
                        <span onClick={handleLocale} className={cx("button", !activeLocale && "active")}>
                            RU
                        </span>
                        <div className={cx("line")} />
                        <span onClick={handleLocale} className={cx("button", activeLocale && "active")}>
                            EN
                        </span>
                    </div>
                    {windowScreen >= 1100 && (
                        <div className={cx("social")}>
                            <TelegramIcon
                                className={cx("icon")}
                                onClick={() => href.push("https://t.me/tezamchanel")}
                            />
                            <InIcon className={cx("icon")} onClick={() => href.push("http://t.me/tezam")} />
                            <YoutubeIcon className={cx("icon")} onClick={() => href.push("http://t.me/tezam")} />
                            <MailIcon className={cx("icon")} onClick={openModal} />
                        </div>
                    )}
                    <Button onClick={open} label="Меню" className={cx("btn-menu")} />
                </div>
            </div>
            <ModalCallBack isOpen={isOpenModal} onClose={closeModal} />
            <ModalMenu isOpen={isOpen} onClose={close} />
        </header>
    );
};
