import cnBind from "classnames/bind";

import { Modal } from "@/components/_Modal/Modal";
import { Footer } from "@/components/Footer";
import { Logo } from "@/components/Logo";
import phone from "@/shared/assests/image 448.png";
import { CloseMenuIcon } from "@/shared/assests/svg/svg";
import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./ModalMenu.module.scss";

const cx = cnBind.bind(styles);

type ModalMenuProps = {
    isOpen: boolean;
    onClose: () => void;
};
export const ModalMenu = ({ isOpen, onClose }: ModalMenuProps) => {
    return (
        <Modal maxWidth="100%" height="100%" className={cx("modal-menu")} isOpen={isOpen} onClose={onClose}>
            <CustomImage src={phone} alt="tezam" className={cx("image")} />
            <div className={cx("header-menu")}>
                <Logo />
                <div className={cx("close")} onClick={onClose}>
                    <span>Закрыть</span>
                    <CloseMenuIcon />
                </div>
            </div>
            <div className={cx("menu")}>
                <h2>Tezam</h2>
                {/* <span>Услуги</span>*/}
                {/* <span>Партнерства</span>*/}
            </div>
            <Footer className={cx("footer-menu")} />
        </Modal>
    );
};
