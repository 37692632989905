import React, { useEffect, useRef } from "react";
import cnBind from "classnames/bind";
import { Accordion, AccordionTab } from "primereact/accordion";

import { ModalCallBack } from "@/components/_Modal/ModalCallBack";
import { TelegramIcon } from "@/shared/assests/svg/svg";
import { useBooleanState } from "@/shared/hooks";
import { Button } from "@/shared/ui/_Button";

import styles from "./Faq.module.scss";

const cx = cnBind.bind(styles);

interface Tab {
    header: string;
    children: React.ReactNode;
}

export const Faq: React.FC = () => {
    const cursorRef = useRef<HTMLDivElement | null>(null);
    const [isOpenModal, openModal, closeModal] = useBooleanState(false);
    useEffect(() => {
        const cursor = cursorRef.current;
        const handleMouseMove = (e: MouseEvent) => {
            if (cursor) {
                cursor.style.left = `${e.clientX}px`;
                cursor.style.top = `${e.clientY}px`;
            }
        };

        document.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const tabs: Tab[] = [
        {
            header: "Почему я должен выбрать Tezam",
            children: (
                <p className={cx("desc")}>
                    Мы предлагаем готовое, проверенное решение, которое адаптируется под ваш бизнес за минимальное
                    время. Приложение тестировалось год в разных сферах, чтобы гарантировать стабильность и удобство.
                </p>
            ),
        },
        {
            header: "В чем уникальность предложения",
            children: (
                <p className={cx("desc")}>
                    Telegram в СНГ — это WeChat нового поколения, где происходят покупки, записи и коммуникация. Мы
                    переносим ваш бизнес в мессенджер, делая его ближе к клиентам.
                </p>
            ),
        },
        {
            header: "Какой опыт компании?",
            children: (
                <p className={cx("desc")}>
                    Мы создавали CRM для салонов красоты, брокерские сервисы, автопрокаты и другие проекты. Опыт
                    разработки и сопровождения позволяет нам решать задачи бизнеса на высоком уровне.
                </p>
            ),
        },
    ];

    return (
        <div className={cx("faq")}>
            <div className={cx("wrapper", "container")}>
                <div className={cx("header")}>
                    <h2>
                        <p>Вопросы</p>
                        <p>и ответы</p>
                    </h2>
                    <Button
                        onClick={openModal}
                        className={cx("button")}
                        label="Telegram"
                        icon={<TelegramIcon className={cx("icon")} />}
                    />
                </div>

                <Accordion className={cx("list")}>
                    {tabs.map((tab) => (
                        <AccordionTab className={cx("item")} key={tab.header} header={tab.header}>
                            {tab.children}
                        </AccordionTab>
                    ))}
                </Accordion>
            </div>
            <div ref={cursorRef} className={cx("cursor")} />
            <ModalCallBack isOpen={isOpenModal} onClose={closeModal} />
        </div>
    );
};
