import cnBind from "classnames/bind";
import Link from "next/link";
import { useRouter } from "next/router";

import { ModalCallBack } from "@/components/_Modal/ModalCallBack";
import { InIcon, MailIcon, TelegramIcon, YoutubeIcon } from "@/shared/assests/svg/svg";
import { useBooleanState } from "@/shared/hooks";

import styles from "./Footer.module.scss";

const cx = cnBind.bind(styles);
type FooterProps = {
    className?: string;
};
export const Footer = ({ className }: FooterProps) => {
    const href = useRouter();
    const [isOpen, open, close] = useBooleanState(false);

    return (
        <footer className={cx("footer", className)}>
            <div className={cx("wrapper", "container")}>
                <div className={cx("social")}>
                    <TelegramIcon className={cx("icon")} onClick={() => href.push("https://t.me/tezamchanel")} />
                    <InIcon className={cx("icon")} onClick={() => href.push("https://t.me/tezam")} />
                    <YoutubeIcon className={cx("icon")} onClick={() => href.push("https://t.me/tezam")} />
                    <MailIcon className={cx("icon")} onClick={open} />
                </div>
                <div className={cx("copyright")}>
                    <div className={cx("text")}>
                        <span>2024 © Tezamteam</span>
                        <span>Все права защищены</span>
                    </div>
                    <div className={cx("text")}>
                        <Link href="/">Политика конфиденциальности</Link>
                        <Link href="/">Правила и условия</Link>
                    </div>
                    <div className={cx("text")}>
                        <Link href="mailto:tezamteam@gmail.com">tezamteam@gmail.com</Link>
                        <Link href="https://t.me/tezam">@dozorir</Link>
                    </div>
                </div>
            </div>
            <ModalCallBack isOpen={isOpen} onClose={close} />
        </footer>
    );
};
