import React from "react";
import cnBind from "classnames/bind";

import { FormCallBack } from "@/components/_Form/FormCallBack";
import { Footer } from "@/components/Footer";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";

import styles from "./Form.module.scss";

const cx = cnBind.bind(styles);
export const Form = () => {
    const { windowScreen } = useResizeContext();

    return (
        <div className={cx("form")}>
            <div className={cx("wrapper", "container")}>
                <FormCallBack />
            </div>
            {windowScreen > 1024 && <Footer />}
        </div>
    );
};
