/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-return-assign */
import { useRef, useState } from "react";
import cnBind from "classnames/bind";
import SwiperCore from "swiper";
import { FreeMode, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import { CaseBlock } from "@/view/Main/Case/CaseBlock";
import { listCase } from "@/view/Main/Case/const";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import styles from "./Case.module.scss";

const cx = cnBind.bind(styles);

SwiperCore.use([Navigation, FreeMode]);

export const Case = () => {
    const { windowScreen } = useResizeContext();
    const [active, setActive] = useState(0);
    const listBtn = [
        "Салоны красоты",
        "Интернет-магазины",
        "Сеть фитнес-клубов и тренеры",
        "Стоматологии и клиники",
        "Рестораны и кафе",
        "Агентства недвижимости",
        "Автосервисы и шиномонтажи",
        "Логистические компании",
        "Учебные центры и курсы",
        "Студии йоги и медитации",
    ];

    const swiperRef = useRef<SwiperCore | null>(null);
    const btnSwiperRef = useRef<SwiperCore | null>(null);

    const handleMainSwiperChange = (swiper: SwiperCore) => {
        setActive(swiper.activeIndex);

        if (btnSwiperRef.current) {
            btnSwiperRef.current.slideTo(swiper.activeIndex);
        }
    };

    const handleButtonClick = (index: number) => {
        setActive(index);

        if (swiperRef.current) {
            swiperRef.current.slideTo(index);
        }

        if (btnSwiperRef.current) {
            btnSwiperRef.current.slideTo(index);
        }
    };

    return (
        <div className={cx("case")}>
            <div className={cx("wrapper", "container")}>
                <Swiper
                    className={cx("swiper")}
                    slidesPerView="auto"
                    slideToClickedSlide
                    spaceBetween={20}
                    speed={1000}
                    freeMode
                    onSlideChange={(swiper) => setActive(swiper.activeIndex)}
                    onSwiper={(swiper) => (btnSwiperRef.current = swiper)}
                >
                    {listBtn.map((item, index) => (
                        <SwiperSlide key={item} onClick={() => handleButtonClick(index)} className={cx("swiper-slide")}>
                            <div className={cx("button", { active: active === index })}>{item}</div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {windowScreen > 1024 ? (
                    <div className={cx("content")}>
                        <CaseBlock
                            list={listCase[active].list}
                            caption={listCase[active].caption}
                            img={listCase[active].img}
                        />
                    </div>
                ) : (
                    <Swiper
                        className={cx("swiper")}
                        slidesPerView={1}
                        spaceBetween={20}
                        speed={1000}
                        breakpoints={{
                            1024: { slidesPerView: 1, spaceBetween: 0 },
                        }}
                        onSlideChange={handleMainSwiperChange}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}
                    >
                        {listCase.map((item, index) => (
                            <SwiperSlide className={cx("swiper-slide-mobile")} key={index}>
                                <CaseBlock list={item.list} caption={item.caption} img={item.img} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
        </div>
    );
};
