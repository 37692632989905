import cnBind from "classnames/bind";
import type { StaticImageData } from "next/image";

import { ArrowGradIcon } from "@/shared/assests/svg/svg";
import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./SlideBlock.module.scss";

const cx = cnBind.bind(styles);

type SlideBlockProps = {
    title: string;
    description: string;
    img: StaticImageData;
};
export const SlideBlock = ({ description, img, title }: SlideBlockProps) => {
    return (
        <div className={cx("slide-block")}>
            <div className={cx("left")}>
                <div className={cx("text")}>
                    <span className={cx("title")}>{title}</span>
                    <ArrowGradIcon />
                </div>
                <span className={cx("desc")}>{description}</span>
            </div>
            <div className={cx("right")}>
                <CustomImage className={cx("img")} src={img} alt={title} />
            </div>
        </div>
    );
};
