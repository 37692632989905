import cnBind from "classnames/bind";

import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./Service.module.scss";

const cx = cnBind.bind(styles);
type ServiceProps = {
    src: string;
    title: string;
    desc: string;
};
export const Service = ({ src, title, desc }: ServiceProps) => {
    return (
        <div className={cx("service")}>
            <div className={cx("wrapper")}>
                <h3 className={cx("title")}>{title}</h3>
                <span className={cx("desc")}>{desc}</span>
                <div className={cx("image-container")}>
                    <CustomImage className={cx("image")} width={337} height={200} src={src} alt={title} />
                </div>
            </div>
        </div>
    );
};
