import cnBind from "classnames/bind";

import { CustomImage } from "@/shared/ui/CustomImage";

import styles from "./Item.module.scss";

const cx = cnBind.bind(styles);

type ItemProps = {
    title: string;
    description: string;
    image: string;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
};

export const Item = ({ description, image, title, onMouseEnter, onMouseLeave }: ItemProps) => {
    return (
        <div className={cx("item")} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={cx("image-container")}>
                <CustomImage width={64} height={64} src={image} alt="tezam" />
            </div>
            <div className={cx("text")}>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
};
