import type { GetStaticProps } from "next";

import { PageLayout } from "@/layouts/PageLayout";
import { Main } from "@/view/Main";

export default function Home() {
    return (
        <PageLayout>
            <Main />
        </PageLayout>
    );
}
export const getStaticProps: GetStaticProps = () => {
    return { props: { data: "Это статически сгенерированная страница" } };
};
