import { useEffect, useRef, useState } from "react";
import cnBind from "classnames/bind";
import type { Swiper as SwiperCore } from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ArrowNextIcon, ArrowPrevIcon } from "@/shared/assests/svg/svg";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import { services } from "@/view/Main/Services/const";
import { Service } from "@/view/Main/Services/Service";

import "swiper/css";
import "swiper/css/navigation";

import styles from "./Services.module.scss";

const cx = cnBind.bind(styles);

export const Services = () => {
    const swiperRef = useRef<SwiperCore | null>(null);
    const { windowScreen } = useResizeContext();
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on("slideChange", () => {
                setIsBeginning(swiperRef.current?.isBeginning ?? false);
                setIsEnd(swiperRef.current?.isEnd ?? false);
            });
        }
    }, []);

    return (
        <div className={cx("services")}>
            <div className={cx("wrapper", windowScreen > 1024 && "container")}>
                <h2 className={cx(windowScreen < 1024 && "container")}>Наши услуги</h2>
                {windowScreen > 1024 ? (
                    <div className={cx("list")}>
                        {services.map((service, i) => (
                            <Service key={i} src={service.image} {...service} />
                        ))}
                    </div>
                ) : (
                    <>
                        <Swiper
                            modules={[Navigation]}
                            direction="horizontal"
                            slidesPerView={1}
                            slidesPerGroup={1}
                            spaceBetween={10}
                            enabled
                            breakpoints={{
                                720: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 10, enabled: true },
                                460: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 10, enabled: true },
                            }}
                            navigation={{ nextEl: ".controller-next", prevEl: ".controller-prev" }}
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            className={cx("list", "container")}
                        >
                            {services.map((service, i) => (
                                <SwiperSlide key={i}>
                                    <Service src={service.image} {...service} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={cx("controller")}>
                            <div className={cx("controller-prev", { isBeginning })}>
                                <ArrowPrevIcon />
                            </div>
                            <div className={cx("controller-next", { isEnd })}>
                                <ArrowNextIcon />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
