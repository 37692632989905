import slide_1 from "@/shared/assests/slide/Image (6).png";
import slide_3 from "@/shared/assests/slide/Image (7).png";
import slide_4 from "@/shared/assests/slide/Image (8).png";
import slide_5 from "@/shared/assests/slide/Image (9).png";
import slide_6 from "@/shared/assests/slide/Image (10).png";
import slide_7 from "@/shared/assests/slide/Image (11).png";
import slide_8 from "@/shared/assests/slide/Image (12).png";
import slide_9 from "@/shared/assests/slide/Image (13).png";
import slide_10 from "@/shared/assests/slide/Image (14).png";
import slide_2 from "@/shared/assests/slide/Image (19).png";

export const listSlide = [
    {
        title: "Повышение вовлеченности клиентов",
        description:
            "Приложение работает прямо в Telegram — нет необходимости устанавливать дополнительные программы. Это снижает барьер входа и увеличивает вовлеченность клиентов на 50%.",
        img: slide_1,
    },
    {
        title: "Удобство записей и заказов",
        description:
            "Календарь внутри мессенджера и автоматические напоминания сокращают ошибки и упрощают процесс бронирования. 70% клиентов предпочитают мессенджеры для записи на услуги",
        img: slide_2,
    },
    {
        title: "Экономия времени клиентов",
        description:
            "Ускорение коммуникации через встроенный чат позволяет клиентам получить ответ в среднем на 30% быстрее, чем через звонки или почту",
        img: slide_3,
    },
    {
        title: "Снижение отказов от записи",
        description: "Благодаря напоминаниям в чате пропущенные записи снижаются на 25–40%",
        img: slide_4,
    },
    {
        title: "Рост доступности услуг",
        description: "Клиенты могут сделать заказ или записаться в пару кликов — это повышает частоту обращений на 35%",
        img: slide_5,
    },
    {
        title: "Лёгкость входа для бизнеса",
        description:
            "Не нужно разрабатывать дорогое мобильное приложение — Tezam — готово к работе и полностью кастомизируется под ваши задачи",
        img: slide_6,
    },
    {
        title: "Оптимизация маркетинга",
        description:
            "Встроенные массовые рассылки и персонализированные уведомления в чате дают возможность удерживать клиентов без дополнительных расходов на СМС или email-рассылки",
        img: slide_7,
    },
    {
        title: "Быстрая адаптация к запросам бизнеса",
        description:
            "Приложение можно настроить под конкретные задачи вашего бизнеса: от продаж до работы с персоналом. Настройка занимает от 3 до 5 дней",
        img: slide_8,
    },
    {
        title: "Снижение технических затрат",
        description:
            "Приложение не требует установки и обновлений для клиентов, что снижает расходы на техническое сопровождение на 50%",
        img: slide_9,
    },
    {
        title: "Рост продаж",
        description:
            "Встроенная витрина товаров и услуг, удобная оплата и лёгкость взаимодействия увеличивают средний чек на 20%",
        img: slide_10,
    },
];
