import { useState } from "react";
import cnBind from "classnames/bind";
import { Autoplay, Mousewheel } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ModalViewSlide } from "@/components/_Modal/ModalViewSlide";
import { useResizeContext } from "@/shared/context/WindowResizeProvider";
import { useBooleanState } from "@/shared/hooks";
import { CustomImage } from "@/shared/ui/CustomImage";
import { listSlide } from "@/view/Main/WhyAreWe/const";
import { SlideBlock } from "@/view/Main/WhyAreWe/SlideBlock";

import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css";
import "swiper/css/navigation";

import styles from "./WhyAreWe.module.scss";

const cx = cnBind.bind(styles);

export const WhyAreWe = () => {
    const { windowScreen } = useResizeContext();

    const [isModalImg, openModalImg, closeModalImg] = useBooleanState(false);

    const [isActiveSlide, setActiveSlide] = useState(0);

    const handleSlide = (index: number) => {
        setActiveSlide(index);
        closeModalImg();
    };

    return (
        <div className={cx("why-are-we")}>
            <div className={cx("wrapper", "container")}>
                <div className={cx("header")}>
                    {windowScreen > 1024 ? (
                        <h2 className={cx("title")}>
                            <p>Почему мы</p>
                            <p>делаем</p>
                            <p>
                                это в <span className={cx("caption")}>Telegram</span>
                            </p>
                        </h2>
                    ) : (
                        <h2 className={cx("title")}>
                            Почему мы делаем это в <span className={cx("caption")}>Telegram</span>
                        </h2>
                    )}
                    <div className={cx("text-wrapper")}>
                        <div className={cx("text")}>
                            <p>Ваши клиенты уже в нем.</p>
                            <p>
                                По статистике за октябрь 2024 года
                                <span className={cx("caption")}> Telegram</span> используют 950 млн. человек в месяц
                                ежемесячно, учитывая значительную долю аудитории в России и СНГ
                            </p>
                        </div>
                    </div>
                </div>
                {windowScreen > 500 ? (
                    <Swiper
                        modules={[Mousewheel, Autoplay]}
                        direction="vertical"
                        loop
                        nested
                        mousewheel={{ invert: false }}
                        className={cx("mySwiper")}
                        // autoplay={{ delay: 3000, disableOnInteraction: false }}
                    >
                        {listSlide.map(({ title, description, img }) => (
                            <SwiperSlide key={title}>
                                <SlideBlock title={title} description={description} img={img} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div onClick={openModalImg} className={cx("slide-mobile")}>
                        <h2 className={cx("title-mobile")}>{listSlide[isActiveSlide].title}</h2>
                        <div className={cx("image-mobile")}>
                            <CustomImage
                                width={60}
                                height={60}
                                src={listSlide[isActiveSlide].img}
                                className={cx("img")}
                                alt="tezam"
                            />
                        </div>
                    </div>
                )}
            </div>
            <ModalViewSlide closeModal={handleSlide} isOpen={isModalImg} />
        </div>
    );
};
