import type { MutableRefObject } from "react";
import { useEffect, useRef, useState } from "react";

interface IntersectionObserverOptions {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
}

const useIntersectionObserver = (
    options: IntersectionObserverOptions,
): [MutableRefObject<HTMLDivElement | null>, IntersectionObserverEntry | null] => {
    const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const ref = useRef<HTMLDivElement | null>(null); // Уточняем тип для HTMLDivElement

    useEffect(() => {
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(([entry]) => {
            setEntry(entry);
        }, options);

        const { current: currentElement } = ref;

        if (currentElement) observer.current.observe(currentElement);

        return () => observer.current?.disconnect();
    }, [ref, options]);

    return [ref, entry];
};

export default useIntersectionObserver;
